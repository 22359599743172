import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    onUsersChanged: BehaviorSubject<any>;

    users: []
    constructor(public appInfo: AppInfoService, private http: HttpClient) {
        this.onUsersChanged = new BehaviorSubject([]);
    }

    GetUserWPhoto(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(this.appInfo.appUrl + "api/user/getuserwphoto", this.appInfo.httpOptions).subscribe(data => {
                this.users = data;
                this.onUsersChanged.next(data);
                resolve(data)
            }, reject)
        })
    }

    GetUser(userId): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/user/getuserbyid?userId=" + userId, this.appInfo.httpOptions);

    }
    
getAllUsers() {
    return this.http.get<any>(this.appInfo.appUrl + "api/user/getallusers", this.appInfo.httpOptions);

}
    HasAccessFlow( userId:number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/user/hasaccessflow?userId=${userId}`, this.appInfo.httpOptions);
          }
    getUserGroupIds(userId: number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/user/getusergroupids?userId=${userId}`, this.appInfo.httpOptions);
    }



    getPostList(selectedUser: number, userId: number) {
        return this.http.get(`${this.appInfo.appUrl}api/profile/getpostlist?selectedUser=${selectedUser}&userId=${userId}`, this.appInfo.httpOptions);
    }


    GetEmployeeList(userId): Observable<any> {

        return this.http.get<any>(this.appInfo.appUrl + "api/user/getemployeelist?userId=" + userId, this.appInfo.httpOptions);
    }
    //GetUserList() {
    //    return this.http.get<any>(this.appInfo.appUrl + "api/user/getuserlist" , this.appInfo.httpOptions);
    //}
    setUserActiveFormsNull(userId: number, formOrMenuData: number, formId: number, menuRowId:number,nodeKey:string) {
        return this.http.get(`${this.appInfo.appUrl}api/user/setuseractivetasksnull?userId=${userId}&formOrMenuData=${formOrMenuData}&formId=${formId}
        &menuRowId=${menuRowId}&nodeKey=${nodeKey}`, this.appInfo.httpOptions);
        }

    findSubordinates(userId: number, subordinates: any[] = []): any[] {
        const currentUser = this.appInfo.RiverEntity.users.find(user => user.id === userId);

        if (currentUser ) {
            if (!!currentUser.managerId && currentUser.managerId > 0) {
                if (!subordinates.includes(currentUser.managerId)) {


                    subordinates.push(currentUser.managerId);
                    this.findSubordinates(currentUser.managerId, subordinates);
                }
            }
        }

        return subordinates;
    }

    checkButtonPassword(btnPassword: string, userId: number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/user/checkbuttonpassword?btnPassword=${btnPassword}&userId=${userId}`, this.appInfo.httpOptions);
    }


}
